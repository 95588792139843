import request from '@/utils/request'

// 获取信息
export function handleIndexData(query) {
    return request({
        url: '/door/getHomePageInfo',
        headers: {
            isToken: false
        },
        method: 'get',
        params: query
    })
}