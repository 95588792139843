<template>
  <div class="main-wrap" v-webTitle :data-title="textTitle">
    <!-- 首屏视频区 -->
    <div class="level1-container">
      <video class="fullscreenvideo" id="v1" autoplay muted loop>
        <source :src="param.video" type="video/mp4" />
      </video>

      <!-- 导航菜单 -->
      <div class="navigatoin-wrap">
        <navigation></navigation>
      </div>
    </div>

    <!-- 公司简介 -->
    <div class="profile-wrap">
      <div class="left">
        <div class="line"></div>
        <div class="title">
          <span>Company Profile</span>
          <span>公司简介</span>
        </div>
      </div>
      <div class="right">
        <div class="content">
          <span>{{ param.introduce }}</span>
        </div>
        <div class="btn-wrap" @click="goto(6)">
          <span>我们能为您做什么</span>
          <div class="line"></div>
        </div>
      </div>
    </div>

    <!-- 板块区 -->
    <div class="plate-wrap">
      <div class="left" @click="goto(1)">
        <img :src="param.memberCenter" alt="" />
        <div class="mask"></div>
        <div class="title">
          <span>会员中心</span>
          <span>Member Center</span>
        </div>
      </div>
      <div class="right">
        <div class="level-1">
          <div class="left" @click="goto(2)">
            <img :src="param.serverCenter" alt="" />
            <div class="mask"></div>
            <div class="title">
              <span>服务中心</span>
              <span>Service Center</span>
            </div>
          </div>
          <div class="right" @click="goto(3)">
            <img :src="param.team" alt="" />
            <div class="mask"></div>
            <div class="title">
              <span>专业团队</span>
              <span>Professional team</span>
            </div>
          </div>
        </div>
        <div class="level-2">
          <div class="left" @click="goto(4)">
            <img :src="param.contentCenter" alt="" />
            <div class="mask"></div>
            <div class="title">
              <span>资源中心</span>
              <span>Content Center</span>
            </div>
          </div>
          <div class="right" @click="goto(5)">
            <img :src="param.serverCase" alt="" />
            <div class="mask"></div>
            <div class="title">
              <span>服务案例</span>
              <span>Service Cases</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 优势区 -->
    <div class="superiority-wrap">
      <div class="level-1">
        <div class="line"></div>
        <div class="title">
          <span>Superiority</span>
          <span>我们的优势</span>
        </div>
      </div>
      <div class="level-2">
        <div class="block1">
          <div class="left">
            <img src="../../assets/images/superiority-block-1.png" alt="" />
          </div>
          <div class="right">
            <div class="top">
              <span>{{ param.casePerCnt }}</span>
              <span>+</span>
            </div>
            <div class="bottom">
              {{ param.casePerCnt }}+人次<br />成功累计受理案件{{
                param.casePerCnt
              }}+人次
            </div>
          </div>
        </div>
        <div class="block2">
          <div class="left">
            <img src="../../assets/images/superiority-block-2.png" alt="" />
          </div>
          <div class="right">
            <div class="top">
              <span>{{ param.honourCnt }}</span>
              <span>+</span>
            </div>
            <div class="bottom">
              {{ param.honourCnt }}+项<br />所获荣誉{{ param.honourCnt }}+项
            </div>
          </div>
        </div>
        <div class="block3">
          <div class="left">
            <img src="../../assets/images/superiority-block-3.png" alt="" />
          </div>
          <div class="right">
            <div class="top">
              <span>{{ param.custCnt }}</span>
              <span>+</span>
            </div>
            <div class="bottom">
              {{ param.custCnt }}+家<br />知名客户{{ param.custCnt }}+家
            </div>
          </div>
        </div>
        <div class="block4">
          <div class="left">
            <img src="../../assets/images/superiority-block-4.png" alt="" />
          </div>
          <div class="right">
            <div class="top">
              <span>{{ param.lawyerCnt }}</span>
              <span>+</span>
            </div>
            <div class="bottom">
              {{ param.lawyerCnt }}+人<br />专业律师团队{{ param.lawyerCnt }}+人
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 社会责任区 -->
    <div class="duty-wrap">
      <div class="left">
        <div class="left-content">
          <div class="line"></div>
          <div class="content-box">
            <span>Social Responsibility</span>
            <span>社会责任</span>
            <div class="spilt-line"></div>
            <div class="info">
              {{ param.socialResContent }}
            </div>
            <div class="btn-wrap">
              <span>了解更多</span>
              <i class="iconfont icon--_jiantou"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <img :src="param.socialRes" alt="" />
        <div class="mask"></div>
      </div>
    </div>

    <!-- 底部区 -->
    <footbar></footbar>

    <!-- 侧边栏 -->
    <sidebar></sidebar>
  </div>
</template>

<script>
import navigation from "../../components/Navigation/index";
import footbar from "../../components/Footbar/index";
import sidebar from "../../components/Sidebar/index";
import { handleIndexData } from "@/api/index";
import global from "@/utils/global.js";

export default {
  components: {
    navigation,
    footbar,
    sidebar,
  },

  data() {
    return {
      // 页面标题
      textTitle: "首页",

      // 服务器路径
      serverURL: global.httpUrl,

      // 首页内容
      param: {
        video: "", //视频
        introduce: "", //简介
        memberCenter: "", //会员中心图片
        serverCenter: "", //服务中心图片
        team: "", //专业团队图片
        contentCenter: "", //资源中心图片
        serverCase: "", //服务案例图片
        casePerCnt: "", //优势-累计受理案件
        honourCnt: "", //优势-荣誉数
        custCnt: "", //优势-客户数
        lawyerCnt: "", //优势-专业律师团队
        socialRes: "", //社会责任图片
        socialResContent: "", //社会责任文字
      },
    };
  },

  mounted() {
    this.getIndexData();
  },

  methods: {
    // 获取首页内容
    getIndexData() {
      handleIndexData().then((response) => {
        const res = response.data;
        // console.log(res);

        this.param.video = this.serverURL + res.video;
        this.param.introduce = res.introduce;
        this.param.memberCenter = this.serverURL + res.memberCenter;
        this.param.serverCenter = this.serverURL + res.serverCenter;
        this.param.team = this.serverURL + res.team;
        this.param.contentCenter = this.serverURL + res.contentCenter;
        this.param.serverCase = this.serverURL + res.serverCase;
        this.param.casePerCnt = res.casePerCnt;
        this.param.honourCnt = res.honourCnt;
        this.param.custCnt = res.custCnt;
        this.param.lawyerCnt = res.lawyerCnt;
        this.param.socialRes = this.serverURL + res.socialRes;
        this.param.socialResContent = res.socialResContent;

        document.querySelector("video").load();
      });
    },

    // 导航区跳转
    goto(num) {
      switch (num) {
        case 1:
          this.$router.push({
            path: "/MemberCenter/LoginAndReg",
          });
          break;
        case 2:
          this.$router.push({
            path: "/ServiceCenter/index",
          });
          break;
        case 3:
          this.$router.push({
            path: "/Team/index",
          });
          break;
        case 4:
          this.$router.push({
            path: "/ResourceCenter/DocumentIndex",
          });
          break;
        case 5:
          this.$router.push({
            path: "/Common/list",
            query: {
              type: "fwal",
            },
          });
          break;
        case 6:
          this.$router.push({
            path: "/AboutUs/SituationAndHonor?#situation",
          });
          break;
      }
    },
  },
};
</script>

<style scoped>
.level1-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

.fullscreenvideo {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.navigatoin-wrap {
  margin: 0 auto;
  width: 90%;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  justify-content: flex-end;
}

.profile-wrap,
.superiority-wrap {
  margin: 0 auto;
  width: 75%;
  height: 516px;
  padding-top: 160px;
}

.profile-wrap {
  display: flex;
  justify-content: space-between;
}

.profile-wrap .left,
.superiority-wrap .level-1 {
  display: flex;
}

.profile-wrap .left .line,
.superiority-wrap .level-1 .line {
  width: 36px;
  height: 4px;
  background: #42363c;
}

.profile-wrap .left .title,
.superiority-wrap .level-1 .title {
  margin-left: 46px;
}

.profile-wrap .left .title span,
.superiority-wrap .level-1 .title span {
  display: block;
}

.profile-wrap .left .title span:nth-child(1),
.superiority-wrap .level-1 .title span:nth-child(1) {
  font-size: 40px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #1f1f1f;
  line-height: 0;
}

.profile-wrap .left .title span:nth-child(2),
.superiority-wrap .level-1 .title span:nth-child(2) {
  font-size: 30px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #40363a;
  line-height: 4;
}

.profile-wrap .right {
  width: 545px;
}

.profile-wrap .right .content {
  width: 100%;
}

.profile-wrap .right .content span {
  font-size: 20px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #494546;
}

.profile-wrap .right .btn-wrap {
  margin-top: 30px;
  width: 222px;
  height: 58px;
  background: #1f1f1f;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.profile-wrap .right .btn-wrap span {
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
}

.profile-wrap .right .btn-wrap .line {
  margin-left: 40px;
  width: 13px;
  height: 13px;
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
}

.plate-wrap {
  width: 100%;
  height: 50.625rem;
  display: flex;
}

.plate-wrap .left {
  position: relative;
  width: 40%;
  height: 100%;
}

.plate-wrap .right {
  width: 60%;
  height: 100%;
}

.plate-wrap .right .level-1,
.plate-wrap .right .level-2 {
  display: flex;
  height: 50%;
}

.plate-wrap .right .level-1 .left,
.plate-wrap .right .level-2 .right {
  position: relative;
  width: 60%;
  height: 100%;
}

.plate-wrap .right .level-1 .right,
.plate-wrap .right .level-2 .left {
  position: relative;
  width: 40%;
  height: 100%;
}

.plate-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.6s;
}

.plate-wrap .mask {
  position: absolute;
  z-index: 2;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: 0.36;
}

.plate-wrap .left .mask {
  background-image: linear-gradient(rgba(255, 26, 0, 1) 20%, #000000 70%);
}

.plate-wrap .right .level-1 .left .mask {
  background-image: linear-gradient(rgba(10, 166, 249, 1) 20%, #000000 70%);
}

.plate-wrap .right .level-1 .right .mask {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 20%, #000000 70%);
}

.plate-wrap .right .level-2 .left .mask {
  background-image: linear-gradient(rgba(116, 0, 174, 1) 20%, #000000 70%);
}

.plate-wrap .right .level-2 .right .mask {
  background-image: linear-gradient(rgba(3, 224, 125, 1) 20%, #000000 70%);
}

.plate-wrap .title {
  position: absolute;
  left: 36px;
  bottom: 45px;
  z-index: 3;
}

.plate-wrap span {
  display: block;
  font-size: 28px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
  line-height: 2;
}

.plate-wrap .left,
.plate-wrap .right .level-1 .left,
.plate-wrap .right .level-1 .right,
.plate-wrap .right .level-2 .left,
.plate-wrap .right .level-2 .right {
  overflow: hidden;
  cursor: pointer;
}

.plate-wrap .left:hover img,
.plate-wrap .right .level-1 .left:hover img,
.plate-wrap .right .level-1 .right:hover img,
.plate-wrap .right .level-2 .left:hover img,
.plate-wrap .right .level-2 .right:hover img {
  transform: scale(1.2);
}

.superiority-wrap .level-2 {
  display: flex;
}

.superiority-wrap .level-2 .block1,
.superiority-wrap .level-2 .block2,
.superiority-wrap .level-2 .block3,
.superiority-wrap .level-2 .block4 {
  margin-left: 80px;
  flex: 1;
  display: flex;
}

.superiority-wrap .level-2 .block1 .left,
.superiority-wrap .level-2 .block2 .left,
.superiority-wrap .level-2 .block3 .left,
.superiority-wrap .level-2 .block4 .left {
  position: relative;
}

.superiority-wrap .level-2 .block1 .left {
  width: 50px;
  height: 50px;
}

.superiority-wrap .level-2 .block2 .left {
  width: 41px;
  height: 50px;
}

.superiority-wrap .level-2 .block3 .left {
  width: 44px;
  height: 38px;
}

.superiority-wrap .level-2 .block4 .left {
  width: 36px;
  height: 44px;
}

.superiority-wrap .level-2 .block1 .left img,
.superiority-wrap .level-2 .block2 .left img,
.superiority-wrap .level-2 .block3 .left img,
.superiority-wrap .level-2 .block4 .left img {
  position: absolute;
  top: 9px;
  width: 100%;
  height: 100%;
}

.superiority-wrap .level-2 .block1 .right,
.superiority-wrap .level-2 .block2 .right,
.superiority-wrap .level-2 .block3 .right,
.superiority-wrap .level-2 .block4 .right {
  margin-left: 20px;
}

.superiority-wrap .level-2 .block1 .right .top,
.superiority-wrap .level-2 .block2 .right .top,
.superiority-wrap .level-2 .block3 .right .top,
.superiority-wrap .level-2 .block4 .right .top {
  position: relative;
}

.superiority-wrap .level-2 .block1 .right .top span:nth-child(1),
.superiority-wrap .level-2 .block2 .right .top span:nth-child(1),
.superiority-wrap .level-2 .block3 .right .top span:nth-child(1),
.superiority-wrap .level-2 .block4 .right .top span:nth-child(1) {
  font-size: 68px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #1f1f1f;
  line-height: 1;
  display: inline-block;
}

.superiority-wrap .level-2 .block1 .right .top span:nth-child(2),
.superiority-wrap .level-2 .block2 .right .top span:nth-child(2),
.superiority-wrap .level-2 .block3 .right .top span:nth-child(2),
.superiority-wrap .level-2 .block4 .right .top span:nth-child(2) {
  position: absolute;
  top: -24px;
  font-size: 30px;
  font-family: Adobe Heiti Std;
  font-weight: bolder;
  color: #1f1f1f;
  display: inline-block;
}

.superiority-wrap .level-2 .block1 .right .bottom,
.superiority-wrap .level-2 .block2 .right .bottom,
.superiority-wrap .level-2 .block3 .right .bottom,
.superiority-wrap .level-2 .block4 .right .bottom {
  margin-top: 20px;
  margin-left: 5px;
  font-size: 0.75rem;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #1f1f1f;
}

.duty-wrap {
  display: flex;
  margin-left: auto;
  width: 88%;
  height: 734px;
}

.duty-wrap .left {
  position: relative;
  width: 35%;
  height: 100%;
  background: #1f1f1f;
}

.duty-wrap .left .left-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-44%, -65%);
  width: 80%;
  height: 500px;
}

.duty-wrap .left .left-content .line {
  float: right;
  width: 14px;
  height: 14px;
  border-top: 3px solid #ffffff;
  border-right: 3px solid #ffffff;
}

.duty-wrap .left .left-content .content-box {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.duty-wrap .left .left-content .content-box span:nth-child(1) {
  font-size: 2.5rem;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
}

.duty-wrap .left .left-content .content-box span:nth-child(2) {
  font-size: 1.875rem;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #fefefe;
}

.duty-wrap .left .left-content .content-box .spilt-line {
  margin-top: 20px;
  width: 36px;
  height: 4px;
  background: #ffffff;
}

.duty-wrap .left .left-content .content-box .info {
  width: 87%;
  margin-top: 40px;
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
  line-height: 30px;
}

.duty-wrap .left .left-content .content-box .btn-wrap {
  margin-top: 160px;
  width: 203px;
  height: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border: 1px solid #ffffff;
  cursor: pointer;
}

.duty-wrap .left .left-content .content-box .btn-wrap span {
  font-size: 16px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
}

.duty-wrap .left .left-content .content-box .btn-wrap i {
  font-size: 24px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
}

.duty-wrap .right {
  position: relative;
  width: 65%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}

.duty-wrap .right img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  transition: all 0.6s;
}

.duty-wrap .right:hover img {
  transform: scale(1.2);
}

.duty-wrap .right .mask {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: 0.36;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 20%, #000000 60%);
}
</style>